import React, {useState, useLayoutEffect} from "react"
import useTranslations from "../utils/useTranslations"
import Logo from "../components/logo"
import Button from "../components/button"
import LocalizedLink from "../components/localizedLink"
import imgWeb from "../images/homepage/screen-web-flat.png"
import imgWebMac from "../images/products/heroes/web-mac.png"
import imgClassic from "../images/products/heroes/classic-flat.jpg"
import imgAndroid from "../images/homepage/screen-android-mobile.png"
import arrowBlack from "../images/icons/arrow-black.svg"
import { ANDROID_LINK_FREE, getAndroidLink } from "../utils/getAndroidLink";

const NotFound = ({ pageContext: {text, locale} }) => {
const t = useTranslations(text)

  	const [dataOs, setOs] = useState({
  		isMac:false,
  		isAndroid:false
  	});
	const [androidLinkFree, setAndroidLinkFree] = useState(ANDROID_LINK_FREE)

  	useLayoutEffect(()=>{
  		if(window){
	  		setOs({
	  			isMac: window.gatsbyIsMac,
	  			isAndroid : window.gatsbyIsAndroid
	  		})
  		}

  		setAndroidLinkFree(getAndroidLink('free'))
  	},[])

	const badgeLink = `/images/google-play-badges/google-play-badge-${locale}.png`


	let content  = <>
		<div className="col-md-6 d-flex justify-content-between text-center flex-column not-found align-center">
			<div>
		  		<Logo productName="Web" color="black" tag="p" />
		  		<p className="px-5 mt-4 mb-0">{t(`Stream torrents while you download. BitTorrent Web is the most trusted web torrent client.`)}</p>
			</div>
			<div>
		  		<img  className="img-fluid align-self-center mt-5 flex-shrink-0 not-found-img" src={imgWeb} alt="BitTorrent Web"></img>
                <div style={{fontSize: '10px'}} className="mt-2 mb-4 text-center"><a id="four-o-four-win-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>

				<div>
		      		<Button href="/products/win/bittorrent-web-compare" id="four-o-four-win-utweb-dl" className="btn-primary text-spacing-1 mt-0  mb-2 button-filled text-spacing-1"> {t(`Free Download`)} </Button>
					<div>
						<LocalizedLink
						id="four-o-four-win-utweb-learnmore" 
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
						to="/products/win/bittorrent-web-free/">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
					</div>
				</div>
			</div>
		</div>
		<div className="col-md-6 d-flex justify-content-between text-center flex-column align-center mt-5 mt-md-0">
			<div>
		  		<Logo productName="Classic" color="black" tag="p" />
		  		<p className="px-5 mt-4 mb-0">{t(`The original torrent desktop app for bulk downloading.`)}</p>
			</div>
			<div>
		  		<img  className="img-fluid align-self-center mt-5 flex-shrink-0 not-found-img" src={imgClassic} alt="BitTorrent Classic"></img>
                <div style={{fontSize: '10px'}} className="mt-2 mb-4 text-center">&nbsp;</div>
				<div>
		      		<Button href="/products/win/bittorrent-classic-compare" id="four-o-four-win-classic-dl" className="btn-primary text-spacing-1 mt-0  mb-2 button-filled text-spacing-1"> {t(`Free Download`)} </Button>
					<div>
						<LocalizedLink
						id="four-o-four-win-classic-learnmore"  
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
						to="/products/win/bittorrent-classic-free/">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
					</div>
				</div>
			</div>
		</div>
	</>

  	if(dataOs.isMac){
  		content = <>
			<div className="offset-md-3 col-md-6 d-flex justify-content-between text-center flex-column align-center">
	      		<Logo productName="Web" color="black" tag="p" />
	      		<p className="px-3 mt-4 mb-0">{t(`Stream torrents while you download with the best torrent client for Mac.`)}</p>
	      		<img  className="img-fluid align-self-center mt-5 flex-shrink-0 not-found-img" src={imgWebMac} alt="BitTorrent Web"></img>
                <div style={{fontSize: '10px'}} className="mt-2 mb-4 text-center"><a id="four-o-four-mac-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>

				<div>
		      		<Button href="/downloads/complete" id="four-o-four-mac-utweb-dl" className="btn-primary text-spacing-1 mt-0  mb-2 button-filled text-spacing-1"> {t(`Free Download`)} </Button>
					<div>
						<LocalizedLink
						id="four-o-four-mac-utweb-learnmore"  
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
						to="/products/mac/bittorrent-web-free/">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
					</div>
				</div>
			</div>
  		</>
  	}
  	else if(dataOs.isAndroid){
  		content = <>
			<div className="offset-md-3 col-md-6 d-flex justify-content-start text-center flex-column align-center">
	      		<Logo productName="Android" color="black" tag="p" />
	      		<p className="px-md-5 mt-4 mb-0">{t(`The most trusted mobile torrent downloader available on Google Play.`)}</p>
				<div>
					<a id="four-o-four-android-dl" className="pointer" href={androidLinkFree}>
						<img className="img-fluid google-play-button" src={badgeLink} alt="BitTorrent Android Google Play"></img>
					</a>
					<div>
						<LocalizedLink
						id="four-o-four-android-learnmore"
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
						to="/products/android/bittorrent-android-free/">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
					</div>
				</div>
	      		<img  className="img-fluid align-self-center my-5 flex-shrink-0 android-hero" src={imgAndroid} alt="BitTorrent Web"></img>
			</div>

  		</>

  	}

	return(
		<div className="notFound">
			<div className="container">
				<div className="row my-5 py-3">
					<div className="col-12 text-center mb-3">
						<p className="h1 not-found-header">{t(`Um... We couldn't find that page.`)}</p>
						<p className="not-found-subheader px-1">{t(`Let us help you find what you're looking for.`)}</p>
					</div>
					{content}
				</div>
			</div>
		</div>
	)

}

export default NotFound
